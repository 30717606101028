* {
  font-family: "Open Sans", sans-serif !important;
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  border: 0;
  color: #282828;
}
