.container {
  padding: 50px 0;

  .aboutContainer {
    display: grid;
    grid-template-columns: repeat(2, 50%);

    .left,
    .right {
      padding: 10px;
    }

    .left {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 80%;
        object-fit: cover;
      }
    }

    .right {
      .aboutDescription {
        text-align: justify;
        letter-spacing: 1px;
      }

      h2 {
        font-size: 30px;
        margin-bottom: 0;
      }

      .trainingTitle {
        margin: 0;
        color: gray;
        text-transform: uppercase;
        margin-top: 10px;
      }
    }

    @media (max-width: 767px) {
      grid-template-columns: 100%;

      .left {
        margin-bottom: 20px;
      }
    }
  }
}
