.footerContainer {
  .footerInfo {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .copy {
      color: white;
    }

    img {
      height: 50px;
    }

    @media (max-width: 1023px) {
      justify-content: center;

      .copy {
        text-align: center;
      }
    }
  }
}
