
.descriptionsContainer {
    padding: 50px 0;

    .grid {
        display: grid;
        grid-template-columns: repeat(2, 50%);

        .description {
            padding: 40px 20px;

            h3 {
                margin-top: 0;
            }

            .border {
                width: 80px;
                height: 2px;
                background: black;
            }

            p {
                text-align: justify;
            }
        }

        @media(max-width: 767px) {
            grid-template-columns: 100%;

            .description {
                padding: 10px 0;
            }
        }
    }
}