
.focusContainer {
    background: white;
    padding: 50px 0;

    .infoContainer {
        display: grid;
        grid-template-columns: 40% repeat(2, 30%);
        .focusTitle, .section {
            padding: 20px;
        }

        .focusTitle {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            h2 {
                font-size: 50px;
                color: black;

                @media(max-width: 1024px) {
                    font-size: 40px;
                }
            }

            .border {
                height: 4px;
                width: 40%;
                background: black;
            }
        }

        .section {
            img {
                width: 100%;
                object-fit: cover;
                border-radius: 5px;
            }

            p {
                text-align: justify;
                margin: 30px 0;
                letter-spacing: 1px;
            }
        }
    }

    @media(max-width: 1023px) {
        padding: 25px 0;

        .infoContainer {
            grid-template-columns: 100%;

            .focusTitle, .section {
                padding: 0;
            }

            .focusTitle {
                margin-bottom: 20px;

                h2 {
                    font-size: 33px!important;
                }

                .border {
                    height: 3px;
                }
            }
        }
    }
}