.principalContainer {
  width: 100%;
  background: black;
  height: auto;
  padding: 20px 0;

  .sectionOne,
  .sectionTwo {
    display: grid;
    grid-template-columns: 55% 45%;
    margin: 10px 0;

    .imgSection {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 90%;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    .sectionText {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h2,
      p {
        width: 100%;
        color: white;
      }

      h2 {
        text-align: center;
        font-size: 40px;
        text-transform: uppercase;
        font-style: italic;
      }

      p {
        letter-spacing: 1px;
        text-align: justify;
        line-height: 30px;
        font-size: 14px;
      }
    }
  }

  @media (max-width: 767px) {
    .sectionOne,
    .sectionTwo {
      grid-template-columns: 100%;
    }
  }
}
