.joinContainer {
  background: black;

  .infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 230px;

    h2 {
      color: white;
      font-size: 40px;
      text-align: center;
      width: 100%;
      margin-top: 0;
    }

    button {
      width: 200px;
      height: 55px;
      outline: none;
      border: none;
      background: white;
      color: black;
      font-size: 15px;
      text-transform: uppercase;
      border-radius: 5px;
      cursor: pointer;
      font-weight: bold;
      transition: all 0.5s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
