.transformationsContainer {
  background: black;
  padding-bottom: 70px;

  .title {
    color: white;
    font-size: 40px;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 28px;
    }
  }

  .scroll {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .arrow {
      z-index: 1;
      background: white;
      width: 40px;
      height: 40px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      box-shadow: 2px 2px 5px grey;
      cursor: pointer;
    }

    .scrollContainer {
      display: flex;
      align-items: center;
      scroll-behavior: smooth;

      .transformation {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
          width: 250px;
        }

        &_footer {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;

          .left,
          .right {
            padding: 0 10px;
            position: relative;
            display: flex;
            align-items: center;

            img {
              width: auto !important;
            }
          }

          .left {
            background: black;
            width: 50%;
            justify-content: flex-start;

            .triangle_down_left {
              width: 0;
              height: 0;
              border-bottom: 50px solid black;
              border-right: 50px solid transparent;
              position: absolute;
              right: -50px;
              bottom: 0;
              z-index: 1;
            }

            img {
              height: 50px;
            }
          }

          .right {
            background: white;
            width: 50%;
            justify-content: space-between;
            padding-left: 50px;

            p {
              margin: 0;
              font-size: 13px;
              font-weight: bold;
              text-transform: capitalize;
            }

            img {
              height: 30px;
            }
          }
        }
      }
    }
  }
}
