.headerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: black;
  height: 40px;

  a {
    color: white;
    text-decoration: none;
    font-size: 13px;

    span {
      margin-left: 8px;
    }
  }
}
