.coverContainer {
  height: 500px;
  position: relative;

  .coverImg {
    width: 100%;
    object-fit: cover;
    height: 100%;
    filter: grayscale(80%);
  }

  .shadow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 -100px 120px black inset;
    z-index: 1;
  }

  .infoContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    flex-direction: column;

    .logo {
      width: 200px;
    }

    .coverTitle {
      color: white;
      margin: 0;
      font-family: "Staatliches", cursive;
      font-size: 45px;
      letter-spacing: 2px;
      text-align: center;
      padding: 0 2%;
      text-shadow: 1px 1px 15px black;
    }

    .btnContainer {
      margin-top: 20px;
      width: 500px;
      display: flex;
      justify-content: space-around;

      button,
      a {
        height: 50px;
        width: 200px;
        border: none;
        border-radius: 5px;
        outline: none;
        font-size: 13px;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: bold;

        transition: all 0.5s;

        &:hover {
          transform: scale(1.1);
        }
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: white;
      }

      .btnContact {
        background: white;
        color: black;
      }

      .btnMore {
        background: black;
        color: white;
      }
    }
  }

  @media (max-width: 767px) {
    .infoContainer {
      .btnContainer {
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        button,
        a {
          margin-top: 15px;
        }
      }
    }
  }
}
