.priceContainer {
  background: black;
  padding: 20px 0;

  .infoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .priceContainer {
      width: 300px;

      .priceText {
        font-size: 50px;
        color: white;
        font-weight: bold;
        text-align: center;
        margin: 0;
        position: relative;

        .dollar {
          position: absolute;
          top: 5px;
          left: 25%;
        }
      }

      .pay {
        text-transform: uppercase;
        text-align: center;
        color: gray;
        margin: 0;
        letter-spacing: 2px;
      }

      .infoText {
        color: white;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        font-style: italic;
      }

      .btnContainer {
        margin-top: 20px;
        display: flex;
        justify-content: center;

        button {
          height: 50px;
          width: 200px;
          border: none;
          border-radius: 5px;
          outline: none;
          font-size: 13px;
          cursor: pointer;
          text-transform: uppercase;
          font-weight: bold;

          transition: all 0.5s;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
